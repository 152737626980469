<template>
  <div class='unity' v-html="$t('description.unity')"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style lang='scss' scoped>

</style>